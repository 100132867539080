import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import firebase from 'gatsby-plugin-firebase';
import React, { useEffect } from 'react';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import Footer from '@components/Footer/Footer';
// Modules
import Header from '@components/Header/Header';
import InsuranceChecker from '@components/insurance-checker';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SocialSharing from '@components/SocialSharing/SocialSharing';
import TopBar from '@components/TopBar/TopBar';
import { withLocale, withTracking } from '@hocs';
import { useTracking } from '@hooks';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const InsuranceCheckerPage = () => {
    const { t } = useTranslation();
    const { trackPageView } = useTracking();
    
    useEffect(() => {
        trackPageView();
    }, []);

    // FIREBASE EVENTS
    useEffect(() => {
        if (!firebase) {
            return;
        }

        firebase.analytics().logEvent('insurance_checker_page_visit', {
            time_log: Date.now(),
        });
    }, [firebase]);

    return (
        <Layout>
            <Seo title={`${t('insurance.checker.page.title')} | Aepsy`} />
            <div className="global_theme-green">
                <TopBar />
                <Section theme="light" spacingBottom="m">
                    <Header
                        theme="light"
                        size="thin"
                        title={t('insurance.checker.page.title')}
                        text={t('insurance.checker.page.subtitle')}
                    />
                </Section>

                <Section theme="soft" container="large" spacingBottom="xl">
                    <div className="BuchenQuestion--module is-large">
                        <Module padding="l" radius="xl" highlighted>
                            <InsuranceChecker />
                        </Module>
                    </div>

                    <div className="g_center">
                        <SocialSharing theme="white" />
                    </div>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(InsuranceCheckerPage));
